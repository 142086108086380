import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ANALYSES_URL } from '../../config/config.api';
import { axiosClient as axios } from '../../utils/axios-instance';

import { AnalysisPayload, Bins, AnalysisStatus } from './interfaces';

const defaultHistogram: Bins = {
  6: {
    frequencies: [],
    limits: [],
  },
  7: {
    frequencies: [],
    limits: [],
  },
  8: {
    frequencies: [],
    limits: [],
  },
  9: {
    frequencies: [],
    limits: [],
  },
  10: {
    frequencies: [],
    limits: [],
  },
};

const initialState: AnalysisStatus = {
  analysis: {
    _id: '',
    code: '',
    createdAt: '',
    campusId: {
      _id: '',
      name: '',
    },
    type: '',
    phaseType: '',
    analysisIds: [],
    pointSizeRatio: 0,
    inputData: {
      sampleWeight: 0,
      stage: 0,
      maturationId: {
        name: '',
      }
    },
    resultData: {
      weightGroup: [],
      countGroup: [],
      labels: [],
      averageLength: 0,
      averageWeight: 0,
      histogramBins: defaultHistogram,
      histogramLengthBins: defaultHistogram,
      histogramPigmentation: [],
      larvaeNumber: 0,
      larvaePerGram: 0,
      standardDeviation: 0,
      uniformity: 0,
      variationCoefficient: 0,
      variationCoefficientLength: 0,
      animalsAboveAverageLength: 0,
      animalsAboveAverageWeight: 0,
      animalsBelowAverageLength: 0,
      animalsBelowAverageWeight: 0,
    },
    imageData: {
      original: {
        location: [0, 0],
        url: '',
        key: '',
        dateTime: ''
      },
      reduced: {
        key: '',
        url: ''
      },
    },
    inferenceData: {
      weightGroups: {
        1: {
          area: 0,
          animals: []
        },
        2: {
          area: 0,
          animals: []
        },
        3: {
          area: 0,
          animals: []
        },
        4: {
          area: 0,
          animals: []
        },
        5: {
          area: 0,
          animals: []
        }
      },
    },
    growOutSizes: {
      whole: {
        labels: [],
        ranges: [],
        values: [],
      },
      tail: {
        labels: [],
        ranges: [],
        values: [],
      },
    },
    sowingId: {
      _id: '',
      status: '',
      active: false,
      code: '',
      name: '',
      startDate: '',
      naupliusNumber: 0,
      litersNumber: 0,
      createdAt: '',
      comment: '',
      harvestQuantity: 0,
      phaseType: '',
    },
  },
  allAnalysis: [],
  currentIndex: 0,
  isLoading: false,
  bin: 6,
};

export const detailAnalysisSlice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    setAnalysis: (state: AnalysisStatus, action: PayloadAction<AnalysisPayload>) => {
      state.analysis = action.payload.analysis;
      state.allAnalysis = action.payload.allAnalysis;
    },
    setIsLoading: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBin: (state: AnalysisStatus, action: PayloadAction<number>) => {
      state.bin = action.payload;
    },
    setCurrentIndex: (state: AnalysisStatus, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
  },
});

export const {
  setAnalysis,
  setIsLoading,
  setBin,
  setCurrentIndex
} = detailAnalysisSlice.actions;

export const fetchAnalysis = (params: { analysisId: string; token: string }) => async (dispatch: Function) => {
  const { analysisId, token } = params;
  dispatch(setIsLoading(true));

  const url = `${ANALYSES_URL}/${analysisId}/token/${token}`;

  try {
    const response = await axios.get(url);
    dispatch(setAnalysis(response.data));
  } catch (e: any) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export default detailAnalysisSlice.reducer;
