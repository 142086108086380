import { Vector2d } from 'konva/types/types';
import { KonvaEventObject } from 'konva/types/Node';
import { Stage, Layer, Image, Circle } from 'react-konva';

import { stockingPhaseTypes } from '../../../config/commons';
import { AnimalsByGroup, Larvae, Percentile } from '../../../pages/Analysis-Detail/interfaces';

interface PointsSampleProps {
  points: (JSX.Element | null)[][] | null;
  sample: {
    x: number;
    y: number;
    image: CanvasImageSource | undefined;
    size?: number | undefined;
  };
  stage: {
    dragBoundFunc?: ((pos: Vector2d) => Vector2d) | undefined;
    draggable?: boolean | undefined;
    height: number;
    width: number;
    y: number;
    x: number;
    onContextMenu?(evt: KonvaEventObject<PointerEvent>): void;
  };
}

export default function PointsSample (props: PointsSampleProps) {
  const { points, stage, sample } = props;
  const { dragBoundFunc, draggable, height, onContextMenu, width, x, y } = stage;
  const { image, y: imageY, x: imageX, size } = sample;

  return (
    <Stage
      width={width}
      height={height}
      draggable={draggable}
      dragBoundFunc={dragBoundFunc}
      y={y}
      x={x}
      onContextMenu={onContextMenu}
    >
      <Layer>
        <Image x={imageX} y={imageY} image={image} width={size} height={size} />
      </Layer>
      <Layer>
        {points}
      </Layer>
    </Stage>
  );
}

interface PointsListProps {
  phaseType: string;
  listPercentile: Percentile[] | AnimalsByGroup[];
  offsetX: number;
  showAnimalsRemoved: boolean;
  closeImage: CanvasImageSource | undefined;
  factorResize?: number;
  listLarvae: Larvae[];
  isActive: boolean;
  color: string;
}

const renderPointsList = (props: PointsListProps) => {
  const { phaseType, closeImage, color, isActive, listLarvae, listPercentile, offsetX, showAnimalsRemoved, factorResize = 1 } = props;

  return listLarvae.map((larvae, index) => {
    if (!isActive && !larvae.removed) {
      return null;
    }

    const pointX = (larvae.point[0] + offsetX) / factorResize;
    const pointY = larvae.point[1] / factorResize;

    if (larvae.removed) {
      const sizeCloseIcon = listPercentile[2].listLarvae[0]?.radius * 1.4;
      const radiusCloseIcon = sizeCloseIcon / 2;
      if (showAnimalsRemoved) {
        return <Image key={color + index} x={pointX - radiusCloseIcon} y={pointY - radiusCloseIcon} width={sizeCloseIcon} height={sizeCloseIcon} image={closeImage} />;
      }
      return null;
    }

    if (larvae.previousGroup !== undefined || larvae.added) {
      return <Circle key={color + index} x={pointX} y={pointY} radius={larvae.radius} fill={color} stroke={'white'} strokeWidth={1.5} dash={[3, 2]} />;
    }

    if (phaseType === stockingPhaseTypes.ADULT) {
      return <Circle key={color + index} x={pointX} y={pointY} radius={larvae.radius} fill={color} stroke={'white'} strokeWidth={1.5} />;
    }

    return <Circle key={color + index} x={pointX} y={pointY} radius={larvae.radius} fill={color} />;
  });
};

interface PointsProps {
  phaseType: string;
  listPercentile?: Percentile[];
  animalsByGroup?: AnimalsByGroup[];
  offsetX: number;
  showAnimalsRemoved: boolean;
  closeImage: CanvasImageSource | undefined;
  factorResize?: number;
}

export const renderPoints = (props: PointsProps) => {
  const { phaseType, animalsByGroup, closeImage, listPercentile, offsetX, showAnimalsRemoved, factorResize = 1 } = props;

  if (listPercentile && listPercentile?.length > 0) {
    return listPercentile.map((percentile) => {
      return renderPointsList({ phaseType, closeImage, color: percentile.color, factorResize, listLarvae: percentile.listLarvae, listPercentile, showAnimalsRemoved, offsetX, isActive: percentile.isActive });
    });
  }

  if (animalsByGroup && animalsByGroup?.length > 0) {
    return animalsByGroup.map((percentile) => {
      return renderPointsList({ phaseType, closeImage, color: percentile.color, factorResize, listLarvae: percentile.listLarvae, listPercentile: animalsByGroup, showAnimalsRemoved, offsetX, isActive: percentile.isActive });
    });
  }

  return null;
};
