import i18next from 'i18next';

import { analysisTypes, stockingPhaseTypes } from '../../config/commons';

export const getSampleTitle = (code: string | undefined) => {
  if (code) {
    return i18next.t('detail.sampleAnalyzed') + ' ' + code;
  }

  return i18next.t('detail.sampleAnalyzed');
};

export const getTitleCountAnimalChart = (analysisPhaseType: string) => {
  switch (analysisPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('detail.chart.countPostlarvae');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('detail.chart.countJuveniles');

    case stockingPhaseTypes.ADULT:
      return i18next.t('detail.chart.countShrimps');
  }
};

export const getUnitCountAnimalChart = (stockingPhaseType: string) => {
  switch (stockingPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return 'pl';

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return 'ind';
  }
};

export const isConsolidatedAnalysis = (analysisType: string) => {
  return analysisType === analysisTypes.CONSOLIDATED_JUVENILE_ANALYSIS || analysisType === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS;
};

export const getAreaPercentIncrease = (props: { analysisType: string }) => {
  const { analysisType } = props;

  switch (analysisType) {
    case analysisTypes.GENERAL_LARVAE_ANALYSIS:
    default:
      return 0;

    case analysisTypes.GENERAL_JUVENILE_ANALYSIS:
    case analysisTypes.GENERAL_ADULT_ANALYSIS:
    case analysisTypes.CONSOLIDATED_JUVENILE_ANALYSIS:
    case analysisTypes.CONSOLIDATED_ADULT_ANALYSIS:
      return 10000;
  }
};
