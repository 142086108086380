import cx from 'classnames';
import { useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppHeader from '../AppHeader/AppHeader';
import { Store } from '../../state/store.interface';
import { analysisTypes } from '../../config/commons';
import Content from '../../common/components/Content/Content';
import DotSpinner from '../../common/components/DotSpinner/DotSpinner';

import './Detail.scss';
import Map from './Map';
import Sample from './Sample';
import Histogram from './Histogram';
import DataDetail from './DataDetail';
import styles from './Detail.module.scss';
import { getSampleTitle, isConsolidatedAnalysis } from './helpers';
import GrowOutSizesChart from './GrowOutSizesChart';
import { getTitleCountAnimalChart } from './helpers';
import { fetchAnalysis } from './detailAnalysisSlice';
import HorizontalBarsChart from './HorizontalBarsChart';

type TParams = {
  id: string,
  token: string,
};

const { TabPane } = Tabs;

function DetailAnalysis ({ match }: RouteComponentProps<TParams>) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { allAnalysis, analysis: analysisOriginal, isLoading, currentIndex } = useSelector((state: Store) => state.detail);
  const currentAnalysis = allAnalysis ? allAnalysis[currentIndex] : analysisOriginal;

  const analysisId = match.params.id;
  const token = match.params.token;

  useEffect(() => {
    dispatch(fetchAnalysis({ analysisId, token }));
  }, [dispatch, analysisId, token]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  if (analysisOriginal._id === '') {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  const renderBarsCharts = () => {
    const animalPerGroup = getTitleCountAnimalChart(analysisOriginal.phaseType);

    if (analysisOriginal.type === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS && !!analysisOriginal.growOutSizes?.whole?.labels) {
      return (
        <div className={styles.postlarvaePerGroup}>
          <Content title={t('detail.chart.commercialSizes')} noPadding style={{ marginBottom: '10px' }}>
            <Tabs className={'commercialSizesTabs'}>
              <TabPane
                tab={t('detail.growOutSizes.whole')}
                key={'1'}
              >
                <GrowOutSizesChart
                  containerClassName={styles.containerBarsChart}
                  textClassName={styles.chartTextBarsChart}
                  textInsideBarClassName={styles.chartTextFillWhite}
                  analysisData={analysisOriginal}
                  typeChart={'GROW_OUT_WHOLE'}
                />
              </TabPane>
              <TabPane
                tab={t('detail.growOutSizes.tail')}
                key={'2'}
              >
                <GrowOutSizesChart
                  containerClassName={styles.containerBarsChart}
                  textClassName={styles.chartTextBarsChart}
                  textInsideBarClassName={styles.chartTextFillWhite}
                  analysisData={analysisOriginal}
                  typeChart={'GROW_OUT_TAIL'}
                />
              </TabPane>
            </Tabs>
          </Content>
        </div>
      );
    }

    return (
      <>
        <div className={styles.postlarvaePerGroup}>
          <Content title={animalPerGroup} noPadding style={{ marginBottom: '10px' }}>
            <HorizontalBarsChart
              analysisData={analysisOriginal}
              typeChart={'SHRIPMS_PER_GROUP'}
            />
          </Content>
        </div>
        <div className={styles.weightPerGroup}>
          <Content title={t('detail.chart.weightGroup')} noPadding style={{ marginBottom: '10px' }}>
            <HorizontalBarsChart
              analysisData={analysisOriginal}
              typeChart={'CUMMULATIVE_WEIGHT_PER_GROUP'}
            />
          </Content>
        </div>
      </>
    );
  };

  return (
    <>
      <AppHeader />

      <div className={styles.containerMain}>
        <Row className={styles.body}>
          <Col xs={24} md={12} xl={8} className={cx(styles.histogramColumn, 'histogramColumn')}>
            <div className={styles.histogram} >
              <Content title={t('detail.histogram')} titleClassName={styles.title} noPadding style={{ marginBottom: '10px' }}>
                <Histogram />
              </Content>
            </div>

            <div className={styles.detail} >
              <DataDetail />
            </div>
          </Col>

          <Col xs={24} md={24} xl={8} className={styles.sampleColumn}>
            <div className={styles.sample}>
              <Content title={getSampleTitle(isConsolidatedAnalysis(analysisOriginal.type) ? currentAnalysis?.code : '')} noPadding style={{ marginBottom: '10px' }}>
                <Sample />
              </Content>
            </div>

            <div className={styles.map}>
              <Content title={t('detail.map')} noPadding style={{ marginBottom: '10px' }}>
                <Map />
              </Content>
            </div>
          </Col>

          <Col xs={24} md={12} xl={8} className={styles.barsChartColumn}>
            {renderBarsCharts()}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DetailAnalysis;
