import { environments } from './commons';

const DOMAINS = {
  [environments.DEV]: 'https://api.dev.larvia.ai',
  [environments.STG]: 'https://api.stg.larvia.ai',
  [environments.PROD]: 'https://api.larvia.ai'
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || environments.DEV;
const DOMAIN = DOMAINS[ENVIRONMENT];

const ANALYSIS_SERVICE_URL = `${DOMAIN}/analysis`;

// Analysis URLS
export const ANALYSES_URL = `${ANALYSIS_SERVICE_URL}/analysis`;
export const CAMPUS_URL = `${ANALYSIS_SERVICE_URL}/campus`;

export const STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';