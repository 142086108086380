import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Vector2d } from 'konva/types/types';
import { useTranslation } from 'react-i18next';
import { Stage, Layer, Circle } from 'react-konva';
import { useEffect, useState, useRef } from 'react';
import { Spin, Space, Row, Button, Col, Checkbox, Tooltip } from 'antd';

import { getAreaPercentIncrease } from './helpers';
import { Store } from '../../state/store.interface';
import Icon from '../../common/components/Icon/Icon';
import closeIconSvg from '../../assets/close_icon.svg';
import FullScreenFrame from '../../common/components/FullScreen/FullScreen';
import { getHeight, getWidth, handleResizeEvent } from '../../utils/dimensions';
import { roundWeight, getWeightUnit, stockingPhaseTypes } from '../../config/commons';
import PointsSample, { renderPoints } from '../../common/components/PointsSample/PointsSample';

import './Sample.scss';
import { Percentile } from './interfaces';
import styles from './Sample.module.scss';
import { getSampleTitle } from './helpers';
import PreviousNextButtons from './PreviousNextButtons';
import { groupLabels, createListPercentiles, groupAnimalsOld, groupAnimals } from './math-percentile';

export default function Sample (props: { showOptions?: boolean; containerClassName?: string }) {
  const { containerClassName = styles.container, showOptions = true } = props;
  const xPointSample = -600;
  const yPointSample = -700;

  const [t] = useTranslation();

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [image, setImage] = useState(new window.Image());
  const [closeImage, setCloseImage] = useState(new window.Image());
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const [imageFullScreen, setImageFullScreen] = useState(new window.Image());
  const [activePercentile, setActivePercentile] = useState([true, true, true, true, true]);
  const [showAnimalsRemoved, setShowAnimalsRemoved] = useState(true);

  const [imageSize, setImageSize] = useState(0);
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [heightScreen, setHeightScreen] = useState(window.innerHeight);

  const stageCanvasRef = useRef<HTMLDivElement>(null);
  const { allAnalysis, analysis: analysisOriginal, currentIndex } = useSelector((state: Store) => state.detail);

  const sizeFullScreen = { width: window.innerWidth, height: window.innerHeight };
  const currentAnalysis = allAnalysis ? allAnalysis[currentIndex] : analysisOriginal;
  const analysisPhaseType = currentAnalysis.phaseType;

  useEffect(() => {
    handleResizeEvent(() => {
      setWidthScreen(window.innerWidth);
      setHeightScreen(window.innerHeight);
    });
  }, []);

  useEffect(updateScreenOnResize, [loading, widthScreen, heightScreen]);

  function updateScreenOnResize () {
    if (stageCanvasRef.current) {
      const width = getWidth(stageCanvasRef);
      const height = getHeight(stageCanvasRef);
      setSize({ width: width, height: height });
    }
  }

  useEffect(() => {
    setLoading(true);

    if (!currentAnalysis.imageData.reduced?.url) {
      return;
    }

    const img = new window.Image();
    const urlSample = currentAnalysis.imageData.reduced.url;
    img.src = urlSample;
    img.addEventListener('load', () => {
      setImage(img);
      setImageFullScreen(img);
      setLoading(false);

      const height = img.naturalHeight;
      const width = img.naturalWidth;

      if (height && width && width === height) {
        setImageSize(height);
      }
    });

    const imageCloseIcon = new window.Image();
    imageCloseIcon.src = closeIconSvg;
    imageCloseIcon.addEventListener('load', () => {
      setCloseImage(imageCloseIcon);
    });
  }, [currentAnalysis.imageData.reduced]);

  const showLarvae = (event: any, indexCheckBox: number) => {
    const isActive: boolean[] = [];

    activePercentile.forEach((value, index) => {
      isActive.push(value);

      if (indexCheckBox === index) {
        isActive[index] = event.target.checked;
      }
    });
    setActivePercentile(isActive);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.center}>
          <Spin />
        </div>

        <PreviousNextButtons />
      </div>
    );
  }

  const averageWeight = currentAnalysis.resultData.averageWeight;
  const labelsUnit = getWeightUnit(averageWeight);
  const labels = roundWeight({ value: currentAnalysis.resultData.labels, showUnit: false, average: averageWeight });
  const countGroup = currentAnalysis.resultData.countGroup;
  const larvaeNumber = currentAnalysis.resultData.larvaeNumber;
  const pointSizeRatio = currentAnalysis.pointSizeRatio;
  const analysisType = currentAnalysis.type;
  const weightGroups = currentAnalysis.inferenceData.weightGroups;

  const labelsSort: string[] = groupLabels(labels);

  const palette = ['#FF149380', '#FFA52A80', '#2B9A2180', '#00BFFC80', '#1B62E680'];
  let listPercentile: Percentile[] = createListPercentiles({ labelsSort, countGroup, colors: palette, activePercentile });

  const areaPercentIncrease = getAreaPercentIncrease({ analysisType }) * larvaeNumber;
  listPercentile = pointSizeRatio !== undefined ? groupAnimals({ listPercentile, pointSizeRatio, weightGroups, imageSize }) : groupAnimalsOld({ listPercentile, areaPercentIncrease, weightGroups });

  const dragBound = (position: Vector2d) => {
    return dragBoundFunc(position, image, size);
  };

  const dragBoundFullScreen = (position: Vector2d) => {
    const titleHeight = 61;
    const sizeScreen = { width: sizeFullScreen.width, height: sizeFullScreen.height - titleHeight };
    return dragBoundFunc(position, imageFullScreen, sizeScreen);
  };

  const dragBoundFunc = (position: Vector2d, image: any, sizeScreen: { width: number; height: number }) => {
    let newX = position.x;
    let newY = position.y;

    if (image.width > sizeScreen.width) {
      if (position.x <= -1 * (image.width - sizeScreen.width)) {
        newX = -1 * (image.width - sizeScreen.width);
      }

      if (position.x >= 0) {
        newX = 0;
      }
    } else {
      newX = 0;
    }

    if (image.height > sizeScreen.height) {
      if (position.y <= -1 * (image.height - sizeScreen.height)) {
        newY = -1 * (image.height - sizeScreen.height);
      }

      if (position.y >= 0) {
        newY = 0;
      }
    } else {
      newY = 0;
    }

    return { x: newX, y: newY };
  };

  const renderBorderTransparent = () => {
    if (analysisPhaseType === stockingPhaseTypes.ADULT) {
      return null;
    }

    return (
      <Tooltip placement='top' title={t('detail.legends.withoutBorders.description')} className={styles.legend}>
        <div className={styles.icon}>
          <Stage
            width={18}
            height={18}
          >
            <Layer>
              <Circle key={1} radius={8} x={9} y={9} stroke={'transparent'} fill={'#ffffff4d'} strokeWidth={1} />
            </Layer>
          </Stage>
        </div>

        <div className={styles.item}>
          {t('detail.legends.withoutBorders.title')}
        </div>
      </Tooltip>
    );
  };

  const renderBorderSolid = () => {
    if (analysisPhaseType === stockingPhaseTypes.LARVAE || analysisPhaseType === stockingPhaseTypes.JUVENILE) {
      return null;
    }

    return (
      <Tooltip placement='top' title={t('detail.legends.solidBorder.description')} className={styles.legend}>
        <div className={styles.icon}>
          <Stage
            width={18}
            height={18}
          >
            <Layer>
              <Circle key={1} radius={8} x={9} y={9} stroke={'white'} strokeWidth={1} />
            </Layer>
          </Stage>
        </div>

        <div className={styles.item}>
          {t('detail.legends.solidBorder.title')}
        </div>
      </Tooltip>
    );
  };

  const renderBorderDotted = () => {
    return (
      <Tooltip className={styles.legend} title={t('detail.legends.dottedBorder.description')}>
        <div className={styles.icon}>
          <Stage
            width={18}
            height={18}
          >
            <Layer>
              <Circle key={1} radius={8} x={9} y={9} stroke={'white'} strokeWidth={1} dash={[3, 2]} />
            </Layer>
          </Stage>
        </div>
        <div className={styles.item}>
          {t('detail.legends.dottedBorder.title')}
        </div>
      </Tooltip>
    );
  };

  function renderLegends () {
    return (
      <div className={styles.legends}>
        {renderBorderTransparent()}
        {renderBorderSolid()}
        {renderBorderDotted()}
      </div>
    );
  }

  function renderCheckboxPercentile () {
    const percentiles = listPercentile.map((percentile, index) => {
      return <Row key={index} gutter={24} className={styles.toolRow}>
        <Col span={20} className={styles.col}>
          <Checkbox
            id={`percentile_${index}`}
            onChange={event => showLarvae(event, index)}
            checked={activePercentile[index]}
            className={styles.toolCheckbox}
          >
            <div className={styles.colorBox} style={{ background: `${percentile.color}` }} />
            <span>{percentile.rank} {labelsUnit}</span>
          </Checkbox>
        </Col>

        <Col span={4}>
          {percentile.value}
        </Col>
      </Row>;
    });

    const key = 'removed_animal';

    percentiles.push(
      <Row key={key} gutter={24} className={styles.toolRow}>
        <Col span={24} className={styles.col}>
          <Checkbox
            id={`percentile_${key}`}
            onChange={event => setShowAnimalsRemoved(event.target.checked)}
            checked={showAnimalsRemoved}
            className={styles.toolCheckbox}
          >
            <div className={styles.colorBox}>
              <img src={closeIconSvg} alt='larvia' className={styles.image} />
            </div>

            <span>{t('detail.animalsRemoved')} </span>
          </Checkbox>
        </Col>
      </Row>
    );

    return percentiles;
  }

  let imageFullScreenX = 0;
  if (imageFullScreen.width < sizeFullScreen.width) {
    const imageFullScreenWidthDifference = sizeFullScreen.width - imageFullScreen.width;
    imageFullScreenX = imageFullScreenWidthDifference / 2;
  }

  function renderButtonFullScreen () {
    return (
      <Tooltip title={t('analysis.fullscreen')}>
        <Button id='fullscreen_button' type='text' onClick={() => setShowFullScreenModal(true)} icon={<Icon name='fullscreen' />} size='small' />
      </Tooltip>
    );
  }

  function renderOption () {
    if (showOptions) {
      return (
        <div className={styles.actions}>
          {renderButtonFullScreen()}
        </div>
      );
    }

    return null;
  }

  const thumbnailStage = {
    dragBoundFunc: dragBound,
    draggable: true,
    height: size.height,
    width: size.width,
    y: yPointSample,
    x: xPointSample,
    onContextMenu: (e: any) => e.evt.preventDefault(),
  };

  const thumbnailImage = {
    x: 0,
    y: 0,
    image: image,
  };

  const fullStage = {
    dragBoundFunc: dragBoundFullScreen,
    draggable: true,
    height: sizeFullScreen.height,
    width: sizeFullScreen.width,
    y: -(sizeFullScreen.height) / 2,
    x: 0,
    onContextMenu: (e: any) => e.evt.preventDefault(),
  };

  const fullImage = {
    x: imageFullScreenX,
    y: 0,
    image: imageFullScreen,
  };

  return (
    <div className={cx(containerClassName, 'sample')}>
      {renderOption()}

      <div ref={stageCanvasRef} className={styles.containerImage} id={'sample'}>
        <Space direction='vertical'>
          <PointsSample
            points={renderPoints({ phaseType: analysisPhaseType, closeImage, listPercentile, showAnimalsRemoved, offsetX: 0 })}
            sample={thumbnailImage}
            stage={thumbnailStage}
          />
        </Space>
        <PreviousNextButtons />
      </div>


      <FullScreenFrame
        show={showFullScreenModal}
        title={getSampleTitle(currentAnalysis.code)}
        onClose={() => setShowFullScreenModal(false)}
      >
        <div className={styles.containerImageFullScreen}>
          <PointsSample
            points={renderPoints({ phaseType: analysisPhaseType, closeImage, listPercentile, showAnimalsRemoved, offsetX: imageFullScreenX })}
            sample={fullImage}
            stage={fullStage}
          />
        </div>

        <div className={styles.tools}>
          {renderCheckboxPercentile()}
        </div>

        {renderLegends()}
      </FullScreenFrame>
    </div>
  );
}
